// /** @jsxImportSource theme-ui */
// import { jsx } from 'theme-ui';
import Container from '../components/Container';
import { Highlight } from '../components/Highlight';
import { Spacer } from '../components/Spacer';
import { Text } from '../components/Text';
import { Layout } from '../layout';
import { desktopOnly, phabletOnly } from '../theme/utils.css';
import {
  ArchitectureIcon,
  CraneIcon,
  HouseIcon,
  MedalIcon,
} from '../components/Icons';
import { columnCss, twoColumnCss, stickyCss } from './over-ons.css';
import { Mark, MarkGroup } from '../modules/MarkersList';
import { Box } from '../components/Box';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Headline } from '../components/Headline';
import { Seo } from '../components/Seo';

export default function AboutUs({ data, location }) {
  return (
    <Layout location={location}>
      <Spacer y={10} />
      <Spacer y={10} className={desktopOnly} />
      <Container className={twoColumnCss}>
        <Box className={columnCss}>
          <GatsbyImage
            image={data.file.childImageSharp.gatsbyImageData}
            className={stickyCss}
            alt="Van Woensel Woningbouw"
            role="presentation"
          />
        </Box>
        <Box className={columnCss}>
          <Highlight as={Headline} size="m">
            Van Woensel Woningbouw
          </Highlight>
          <Text size="large">
            Onze firma is een echt familiebedrijf dat al gedurende 4 generaties
            overgaat van vader op zoon. Onze grootvader Emiel Van Woensel is in
            1948 gestart met een kleine firma uit de grond te stampen. Al snel
            gingen zijn twee zonen Jul en Walter met hem mee naar de werf om het
            bouwvakkersberoep te leren en werd de firma steeds bekender in de
            omgeving van Heist-op-den-Berg en omstreken als ‘de mannen van Van
            Noten‘ ! Na het pensioen van onze grootvader stapten ook wij, Johan
            en Chris, de twee zonen van Jul, mee in de firma. ‘Van Woensel
            Woningbouw’ was geboren. Ondertussen was het tijd voor Walter en
            enkele jaren later eveneens voor Jul om de fakkel volledig door te
            geven. Samen met 2 werknemers telde de firma op dat moment 4 leden.
            In augustus 2021 kreeg de firma nog een extra lid bij. Emiel Van
            Woensel, genoemd naar zijn grootvader en zoon van Johan, zorgde voor
            de overgang naar de vierde generatie. We doen ons werk ondertussen
            nog steeds met passie en gedrevenheid. Wat je graag doet, doe je
            goed!
          </Text>
          <Text size="large">
            Onder leiding van onze twee meewerkende bazen Johan en Chris, kunnen
            wij met ons team bergen verzetten. Wegens hun jarenlange ervaring in
            woning- en villabouw, doorgegeven door hun grootvader Emiel, weten
            ze van aanpakken. Daarnaast bestaat ons team uit 3 'top’-metsers om
            'U' tegen te zeggen, al zeggen we het zelf! Samen werken wij als een
            geoliede machine, die elk bouwproject tot in de puntjes wil
            uitvoeren.
          </Text>
        </Box>
      </Container>

      <Spacer y={10} />
      <Spacer y={10} className={phabletOnly} />
      <Container as="section">
        <MarkGroup>
          <Mark title="Woning op maat" icon={ArchitectureIcon}>
            <Text>
              Sinds het ontstaan van de firma zetten wij in op betrokken
              vakmansschap bij het realiseren van de meest uiteenlopende
              bouwprojecten voor diverse opdrachtgevers. Wij werken zowel voor
              particulieren als voor architecten en bouwen gevarieerd naar
              ieders wens. Met ons klein maar goed op elkaar ingespeelde team,
              bouwen bij momenteel zo’n 5 à 6 huizen op jaarbasis en dit in
              verschillende stijlen (traditioneel landelijk, modern landelijk,
              modern, …). Afhankelijk van het ‘type’ woning, kunnen wij u
              verschillende van onze realisaties tonen.
            </Text>
          </Mark>
          <Mark title="Ruwbouwwerken" icon={CraneIcon}>
            <Text>
              Naast een erkend aannemersbedrijf vinden wij het als werkgevers
              enorm belangrijk om één team te vormen samen met onze werknemers.
              Het idee dat beide zaakvoerders nog mee op de werf staan, maakt
              het allemaal wat gemoedelijker. En dat is ook meteen de wijze
              waarop we met onze klanten willen omgaan. We zijn gespecialiseerd
              in funderings- en ruwbouwwerken en proberen ook zo goed mogelijk
              op de hoogte te blijven van de nieuwste bouwtechnieken door ons
              jaarlijks bij te scholen.
            </Text>
          </Mark>
          <Mark title="Winddichte woning" icon={HouseIcon}>
            <Text>
              Hoewel onze firma slechts de fundering en de ruwbouw van uw woning
              verzorgt, vragen vele klanten naar onze ervaring met en mening
              over andere bouwfirma’s zoals dakwerkers, voegers, bezetters,
              ramenplaatsers, ... Door onze hechte samenwerking en een duidelijk
              en productief onderling overleg met deze firma’s, is er steeds een
              goede opvolging gedurende uw ganse bouwproject.
            </Text>
          </Mark>
          <Mark title="Kwaliteit voorop" icon={MedalIcon}>
            <Text>
              We zijn een zeer gedreven team en onze woningen zijn ons
              visitekaartje. Bij de opstart van een nieuw bouwproject, proberen
              we steeds een nuttige en veilige werfinrichting met de nodige
              professionele werftoestellen te voorzien en zorgen we voor de
              levering en het gebruik van kwalitatief sterke bouwmaterialen.
              Onze sterkte bestaat uit onze parate kennis, onze jarenlange
              ervaring en een polyvalent team dat uw droomwoning volgens uw
              wensen kan realiseren.
            </Text>
          </Mark>
        </MarkGroup>
      </Container>
    </Layout>
  );
}

export function Head() {
  return <Seo title="Over ons" />;
}

export const query = graphql`
  query {
    file(name: { eq: "camionnete-vanwoenselwoningbouw" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 915
          formats: [JPG]
          placeholder: BLURRED
          sizes: "(max-width: 995px) 100vw, 300px"
        )
      }
    }
  }
`;
