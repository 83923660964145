import { Children, cloneElement } from 'react';
import {
  descriptionCss,
  titleCss,
  markCss,
  iconCss,
  markSecondColumnCss,
  iconContainerCss,
  markGroupCss,
} from './index.css';

export function MarkGroup({ children }) {
  return <div className={markGroupCss}>{children}</div>;
}

export function Mark({ icon: Icon, title, children }) {
  return (
    <div className={markCss}>
      <div className={iconContainerCss}>
        <Icon className={iconCss} />
      </div>
      <div className={markSecondColumnCss}>
        <h2 className={titleCss}>{title}</h2>
        {Children.map(children, (child) => {
          return cloneElement(child, {
            className: descriptionCss,
            variant: 'ghost',
          });
        })}
      </div>
    </div>
  );
}
